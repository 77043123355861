import styled from "styled-components";

interface StyledMobileSubMenuWrapperProps {
  active: number;
}

export const StyledMobileSubMenuWrapper = styled.section<StyledMobileSubMenuWrapperProps>`
  width: 100%;
  height: 100vh;
  box-sizing: border-box;
  position: fixed;
  z-index: 9;
  background-color: ${({ theme }) => theme.color.white};
  top: 60px;
  overflow: hidden;
  display: none;
  transition: max-height 0.4s ease-in-out;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;

  @media screen and (max-width: ${({ theme }) => theme.mediaScreen.tablet}) {
    max-height: ${({ active }) => (active ? "100vh" : "0px")};
    display: flex;
    flex-direction: column;
    padding: 0px 68px;
  }

  @media screen and (max-width: ${({ theme }) => theme.mediaScreen.mobile}) {
    top: -10px;
    margin-top: 64px;

    max-height: ${({ active }) => (active ? "100vh" : "0px")};
    display: block;
  }
`;

export const StyledMenuWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 81px;
  padding-top: 58px;
`;

export const StyledMenuCon = styled.div`
  display: flex;
  flex-direction: column;
  gap: 22px;
  align-items: center;
`;

export const StyledSpan = styled.span`
  font-size: 18px;
  line-height: 21px;
  font-weight: 700;

  border-bottom: 1px solid black;
  padding-bottom: 1px;

  cursor: pointer;
  white-space: nowrap;
`;
