import { useNavigate } from "react-router-dom";
import {
  StyledWrapper,
  StyledContentWrapper,
  StyledTitle,
  StyledComment,
  StyledContentCon,
  StyledBr,
  StyledResponsiveBr,
  StyledBackgroundImg,
  StyledTablettBr,
} from "./styles";
import Button from "../../../../components/button";
import { useEffect, useState } from "react";

const Banner: React.FC = () => {
  const navigate = useNavigate();

  const [adjustWidth, setAdjustWidth] = useState<number>();
  const [adjustHeight, setAdjustHeight] = useState<number>();

  const calculateDimensions = () => {
    const currentViewWidth = window.innerWidth;
    const currentViewHeight = window.innerHeight;

    if (currentViewWidth / currentViewHeight > 320 / 97) {
      setAdjustHeight(currentViewHeight);
      setAdjustWidth(currentViewWidth);
    } else {
      setAdjustWidth(currentViewWidth);
      setAdjustHeight((currentViewWidth * 97) / 320);
    }

    if (currentViewWidth < 600) {
      if (currentViewWidth / currentViewHeight > 24 / 19) {
        setAdjustHeight(currentViewHeight);
        setAdjustWidth(currentViewWidth);
      } else {
        setAdjustWidth(currentViewWidth);
        setAdjustHeight((currentViewWidth * 19) / 24);
      }
    }
  };
  useEffect(() => {
    calculateDimensions();

    const handleResize = () => {
      calculateDimensions();
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <StyledWrapper style={{ height: adjustHeight }}>
      <StyledContentWrapper>
        <StyledBackgroundImg
          src={adjustWidth > 768 ? "/image/banner.png" : "/image/bannerM.png"}
          $width={adjustWidth}
          $height={adjustHeight >= 768 ? adjustHeight : adjustHeight}
        />
        <StyledContentCon>
          <StyledTitle>지금 바로 이지마스에 등록하세요</StyledTitle>
          <StyledComment>
            군부대와의 더 나은 소통과 효율적인 발주 관리를 원하신다면, 이지마스가 그 해결책입니다.
            {/* 
            <StyledBr />
            <StyledResponsiveBr />
            <StyledTablettBr /> 
            <StyledResponsiveBr /> 지금 바로 이지마스 입점을 통해 군부대에 제품을 알려보세요!
            */}
          </StyledComment>
          <StyledComment>
            지금 바로 이지마스 입점을 통해 군부대에 제품을 알려보세요!            
          </StyledComment>
        </StyledContentCon>
        <div>
          <Button            
            type="button"
            size={adjustWidth > 1300 ? "full" : "medium"}
            color="representative"
            text="무료로 시작하기"
            onClick={() => window.open("https://vendor.easymas.kr", "_blank")}
          />
        </div>
        <div>
          <Button
            type="button"
            size={adjustWidth > 1300 ? "full" : "medium"}
            color="lightBlack"          
            text="매뉴얼 다운로드"
            onClick={() => window.open("https://www.easymas.kr/upload/easymas-vendor.pdf", "_blank")}
          /> 
        </div>
      </StyledContentWrapper>
    </StyledWrapper>
  );
};

export default Banner;
