import {
  Headline1,
  Headline1Orange,
  Paragraph1Gray,
} from "../../../../styles/typography";
import { css, keyframes, styled } from "styled-components";

const frameInAnimation = keyframes`
    0% {
         opacity: 0;
         transform: translateY(10%);
   
    }
    100%{
        opacity: 1;
        transform: translateY(0%);
       
    }
`;
const frameInMobileAnimation = keyframes`
    0% {
         opacity: 0;
         transform: translateY(0%);
   
    }
    100%{
        opacity: 1;
        transform: translateY(0%);
       
    }
`;
interface Props {
  $isFadeActive?: boolean;
  $active?: 1 | 0;
}

const StyledWrapper = styled.section<Props>`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  box-sizing: border-box;
  justify-content: flex-start;
  overflow: hidden;

  height: calc(100vh - 80px);

  ${(props) =>
    props.$isFadeActive &&
    css`
      animation: ${frameInAnimation} 0.5s forwards ease-in-out;
    `}

  @media screen and (max-width: 768px) {
    flex-direction: column;
    height: calc(100vh - 60px);
    // height: 100vh;
  }
`;

const StyledContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  // @media screen and (max-width: ${({ theme }) => theme.mediaScreen.mobile}) {
  //   & > img {
  //     width: 120px;
  //     height: 36px;
  //   }
  //   & > img:first-child {
  //     width: 100px;
  //     height: 34px;
  //   }
  // }
`;

export const StyledInnerContaienr = styled.img`
  object-fit: cover;
  flex: 1;
`;

const StyledTitle = styled.h1`
  margin: 0;
  padding: 0;

  margin-top: 80px;

  white-space: pre-line;
  word-break: keep-all;
  font-size: 65px;
  font-weight: 700;
  line-height: 80px;
  letter-spacing: -0.4px;
  color: black;

  @media screen and (max-width: ${({ theme }) => theme.mediaScreen.mobile}) {
    margin-top: 60px;
    font-size: 42px;
    line-height: 48px;
  }
`;

const StyledOrange = styled.span`
  color: #ff4d00;
  }
`;

const StyledSubTitle = styled(Paragraph1Gray)`
  margin-top: 0px;
  color: black;

  @media screen and (max-width: ${({ theme }) => theme.mediaScreen.mobile}) {
    font-size: 18px;
    line-height: 20px;
  }
`;

const StyledAppCon = styled.div`
  margin-top: 40px;
  display: flex;
  gap: 16px;
`;

const StyledAppClientCon = styled.div`
  display: flex;
  gap: 12px;
  justify-content: center;
  
  // @media screen and (max-width: 875px) {
  //   width: 100%;
  //   align-content: center;
  // }

  // @media screen and (max-width: 768px) {
  //   width: 100%;
  //   justify-content: center;
  //   align-content: center;
  // }
`;

const StyledImageCon = styled.div<Props>`
  display: none;

  @media screen and (max-width: ${({ theme }) => theme.mediaScreen.mobile}) {
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      image-rendering: -moz-crisp-edges;
      image-rendering: -o-crisp-edges;

      image-rendering: crisp-edges;
      overflow-clip-margin: content-box;
      overflow: clip;
      image-rendering: -webkit-optimize-contrast;
      transform: translateZ(0);
      backface-visibility: hidden;
    }
    ${(props) =>
      props.$isFadeActive &&
      css`
        animation: ${frameInMobileAnimation} 0.5s forwards ease-in-out;
      `}
  }
`;

export {
  StyledWrapper,
  StyledContentWrapper,
  StyledTitle,
  StyledSubTitle,
  StyledOrange,
  StyledAppCon,
  StyledImageCon,
  StyledAppClientCon,
};
