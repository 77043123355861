import {
  Headline2,
  Headline3,
  Headline4,
  Paragraph2,
  Paragraph4,
} from "../../../../styles/typography";
import { styled } from "styled-components";

const StyledWrapper = styled.section`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  box-sizing: border-box;

  background-color: ${({ theme }) => theme.color.white};
  padding: 130px 0px 130px 0px;
  @media screen and (max-width: ${({ theme }) => theme.mediaScreen.tablet}) {
    padding: 80px 20px 80px 20px;
  }
  @media screen and (max-width: ${({ theme }) => theme.mediaScreen.mobile}) {
    padding: 60px 20px 60px 20px;
  }
`;
const StyledInnerWrapper = styled.div`
  max-width: 1280px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media screen and (max-width: ${({ theme }) => theme.mediaScreen.tablet}) {
    padding: 0 20px;
    white-space: pre-line;
    word-break: keep-all;
    max-width: 100%;
  }
`;

const StyledContainer = styled.div`
  display: flex;
  width: 100%;
  height: 340px;
  border-radius: 10px;
  align-items: center;
  padding: 0 96px;
  background-color: ${({ theme }) => theme.color.black};
  box-sizing: border-box;
  gap: 150px;
  justify-content: space-between;
  @media screen and (max-width: ${({ theme }) => theme.mediaScreen.tablet}) {
    min-width: 320px;
    padding: 18px 0 0 45px;
    align-items: center;
    margin-top: 40px;

    gap: 32px;
  }
  @media screen and (max-width: 768px) {
    flex-direction: row;
    height: 200px;
    padding: 18px 0 0 20px;
  }

  @media screen and (max-width: 600px) {
    min-width: 320px;
    flex-direction: column;
    align-items: center;
    margin-top: 40px;
    padding: 0 20px;
    height: 320px;
  }
`;

const StyledCommentCon = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;

  @media screen and (max-width: ${({ theme }) => theme.mediaScreen.mobile}) {
    box-sizing: border-box;
    padding: 30px 0;
    gap: 8px;
  }
`;

const StyledFlexWrapper = styled.div`
  display: flex;
  gap: 53px;
  width: 100%;
  margin-top: 54px;
  box-sizing: border-box;
  @media screen and (max-width: ${({ theme }) => theme.mediaScreen.tablet}) {
    flex-direction: column;
  }
`;
const StyledContainerSmall1 = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.color.presentative};
  border-radius: 10px;
  width: 100%;
  height: 318px;
  box-sizing: border-box;
  padding: 60px 0 0 45px;

  @media screen and (max-width: ${({ theme }) => theme.mediaScreen.tablet}) {
    justify-content: center;
    padding: 18px 0 0 45px;
  }
  @media screen and (max-width: ${({ theme }) => theme.mediaScreen.mobile}) {
    padding: 0;
    padding-left: 20px;
    height: 200px;
  }
`;
const StyledContainerSmall2 = styled(StyledContainerSmall1)`
  background-color: #f6f6f6;
  @media screen and (max-width: ${({ theme }) => theme.mediaScreen.mobile}) {
    padding: 0;
    padding-left: 20px;
    height: 200px;
  }
`;

const StyledContentCon = styled.div``;
const StyledImageWrapper = styled.div`
  border-radius: 15px;
  margin-top: 8px;
  @media screen and (max-width: ${({ theme }) => theme.mediaScreen.tablet}) {
    width: 250px;
    margin-top: 90px;
    display: flex;
    justify-content: flex-end;
    & > img {
      width: 250px;
    }
  }
  @media screen and (max-width: ${({ theme }) => theme.mediaScreen.mobile}) {
    display: none;
  }
`;

const StyledBr = styled.br`
  display: block;
  @media screen and (max-width: 768px) {
    display: none;
  }
  @media screen and (max-width: ${({ theme }) => theme.mediaScreen.mobile}) {
    display: none;
  }
`;

const StyledResponsiveBr = styled.br`
  display: none;
  @media screen and (max-width: 768px) {
    display: none;
  }
  @media screen and (max-width: ${({ theme }) => theme.mediaScreen.mobile}) {
    display: block;
  }
`;

export const StyledTabletBr = styled.br`
  display: none;

  @media screen and (max-width: 768px) {
    display: block;
  }
  @media screen and (max-width: ${({ theme }) => theme.mediaScreen.mobile}) {
    display: none;
  }
`;

const StyledMobileImageWrapper = styled.div`
  border-radius: 15px;
  display: none;

  @media screen and (max-width: ${({ theme }) => theme.mediaScreen.tablet}) {
    display: none;
  }
  @media screen and (max-width: 768px) {
    display: flex;
    height: 100%;
    align-items: flex-end;
  }
  @media screen and (max-width: 600px) {
    margin-top: -60px;

    width: 90vw;
    display: flex;
    justify-content: flex-end;
  }
  @media screen and (max-width: 428px) {
    display: flex;
    justify-content: flex-end;
    & > svg {
      width: 150px;
      height: 130px;
    }
  }
`;
const StyledTitle = styled(Headline3)`
  line-height: 80px;
  margin-bottom: 44px;
  @media screen and (max-width: 875px) {
    font-size: 38px !important;
    line-height: 50px !important;
  }
  @media screen and (max-width: ${({ theme }) => theme.mediaScreen.mobile}) {
    font-size: 24px !important;
    line-height: 28px !important;
    font-weight: 700 !important;
    margin-bottom: 0px;
  }
`;

const StyledContentTitle = styled(Headline4)`
  color: ${({ theme }) => theme.color.white};
  @media screen and (max-width: ${({ theme }) => theme.mediaScreen.tablet}) {
    font-size: 44px !important;
    line-height: 60px !important;
  }
  @media screen and (max-width: 875px) {
    font-size: 36px !important;
    line-height: 44px !important;
    font-weight: bold;
  }
  @media screen and (max-width: ${({ theme }) => theme.mediaScreen.mobile}) {
    font-size: 18px !important;
    line-height: 28px !important;
    font-weight: 700 !important;
  }
`;

const StyledContentComment = styled(Paragraph4)`
  color: ${({ theme }) => theme.color.white};
  font-size: 22px !important;
  @media screen and (max-width: ${({ theme }) => theme.mediaScreen.tablet}) {
    font-size: 22px !important;
    line-height: 34px !important;
  }
  @media screen and (max-width: ${({ theme }) => theme.mediaScreen.mobile}) {
    font-size: 16px !important;
    line-height: 22px !important;
    font-weight: 400 !important;
  }
`;

export {
  StyledWrapper,
  StyledContainer,
  StyledTitle,
  StyledImageWrapper,
  StyledInnerWrapper,
  StyledContentTitle,
  StyledCommentCon,
  StyledContentComment,
  StyledFlexWrapper,
  StyledContainerSmall1,
  StyledContainerSmall2,
  StyledMobileImageWrapper,
  StyledBr,
  StyledResponsiveBr,
};
