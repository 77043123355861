import React from "react";
import { StyledMain, HeaderHeightAdjust } from "./style";
import Header from "../header";
import Footer from "../footer";

interface Props {
  children: React.ReactNode;
  backgroundColor?: string;
}

const RootLayout: React.FC<Props> = ({ children, backgroundColor }) => {
  return (
    <div>
      <Header />
      <HeaderHeightAdjust />
      <StyledMain style={{ backgroundColor }}>{children}</StyledMain>
      <Footer />
    </div>
  );
};

export default RootLayout;
