import { useEffect, useState } from "react";
import { useScrollAnimation } from "../../../../hooks/use-scroll-animation";
import {
  StyledWrapper,
  StyledContentWrapper,
  StyledTitle,
  StyledSubTitle,
  StyledOrange,
  StyledAppCon,
  StyledImageCon,
  StyledInnerContaienr,
  // StyledInnerContaienr2,
} from "./styles";

// import { ReactComponent as MainImg } from "../../../../assets/image/company-main.svg";
import { StyledResponsiveBr } from "../banner/styles";
import { StyledTitleCon } from "../../../client/components/app-download/styles";

const appDownloads = [
  {
    url: "https://apps.apple.com/kr/app/id6472038203",
    imgSrc: "/image/app-store-2.svg",
  },
  {
    url: "https://play.google.com/store/apps/details?id=kr.easymas.buyer",
    imgSrc: "/image/play-store-2.svg",
  },
];

const AppDownload = () => {
  const { ref, isInViewport } = useScrollAnimation();
  const [adjustWidth, setAdjustWidth] = useState<number>();
  const [adjustHeight, setAdjustHeight] = useState<number>();
  const [imageSrc, setImageSrc] = useState<string>("/image/company/main4.png");

  const calculateDimensions = () => {
    const currentViewWidth = window.innerWidth;
    const currentViewHeight = window.innerHeight;

    if (currentViewWidth / currentViewHeight > 16 / 9) {
      setAdjustHeight(currentViewHeight);
      setAdjustWidth(currentViewWidth);
    } else {
      setAdjustWidth(currentViewWidth);
      setAdjustHeight((currentViewWidth * 9) / 16);
    }

    if (currentViewWidth <= 900) {
      setImageSrc("/image/company/main5.png");
    } else {
      setImageSrc("/image/company/main4.png");
    }
  };

  useEffect(() => {
    calculateDimensions();

    const handleResize = () => {
      calculateDimensions();
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <StyledWrapper
      ref={ref}
      $isFadeActive={isInViewport}
      // style={{ height: window.innerWidth > 768 && adjustHeight }}
    >
      <StyledContentWrapper>
        <StyledTitleCon>
          <StyledTitle>
            MAS를 손안에
            <StyledResponsiveBr />
            <StyledOrange> EASY MAS</StyledOrange>
          </StyledTitle>
          <StyledSubTitle>군부대 식품발주 보조 통합서비스</StyledSubTitle>
        </StyledTitleCon>
        <StyledAppCon>
          {appDownloads.map(({ url, imgSrc }, index) => (
            <a key={index} href={url} target="_blank">
              <img src={imgSrc} />
            </a>
          ))}
        </StyledAppCon>
      </StyledContentWrapper>
      <StyledInnerContaienr src={"/image/company/main3-2.jpeg"} />
      <StyledImageCon ref={ref} $isFadeActive={isInViewport}></StyledImageCon>
    </StyledWrapper>
  );
};

export default AppDownload;
