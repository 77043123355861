import styled from "styled-components";

export const StyledUserWrapper = styled.div`
  display: none;

  @media screen and (max-width: ${({ theme }) => theme.mediaScreen.mobile}) {
    display: flex;
    align-items: center;

    & > svg {
      margin-right: 6px;
      margin-bottom: 2px;
      font-size: 20px;
    }
  }
`;

export const StyledMobileMenuController = styled.div`
  display: none;

  svg {
    margin-left: 24px;
    font-size: 24px;
    cursor: pointer;
    color: ${({ theme }) => theme.color.black};
  }

  @media screen and (max-width: ${({ theme }) => theme.mediaScreen.mobile}) {
    display: flex;
  }
`;
