import styled from "styled-components";

export const Headline1 = styled.h1`
  color: ${({ theme }) => theme.color.blck};
  font-style: normal;
  font-weight: ${({ theme }) => theme.typography.weight.bold};
  font-size: ${({ theme }) => theme.typography.size.h1}px !important;
  line-height: 60px;
  letter-spacing: 0.4px;

  @media screen and (max-width: ${({ theme }) => theme.mediaScreen.mobile}) {
    font-weight: ${({ theme }) => theme.typography.weight.bold};
    font-size: 38px !important;
    line-height: 48px;
  }
`;
export const Headline1Orange = styled.span`
  color: ${({ theme }) => theme.color.representative};
  font-style: normal;
  font-weight: ${({ theme }) => theme.typography.weight.bold};
  font-size: ${({ theme }) => theme.typography.size.h1}px !important;
  line-height: 20px;
  letter-spacing: 0.4px;
  @media screen and (max-width: ${({ theme }) => theme.mediaScreen.mobile}) {
    font-weight: ${({ theme }) => theme.typography.weight.bold};
    font-size: 38px !important;
    line-height: 48px;
  }
`;

export const Headline1White = styled.h1`
  color: ${({ theme }) => theme.color.white};
  font-style: normal;
  font-weight: 600;
  font-size: 68px !important;
  line-height: 82px;
  letter-spacing: 0.4px;
  @media screen and (max-width: ${({ theme }) => theme.mediaScreen.mobile}) {
    font-weight: ${({ theme }) => theme.typography.weight.bold};
    font-size: 38px !important;
    line-height: 48px;
  }
`;
export const Headline2 = styled.h2`
  color: ${({ theme }) => theme.color.black};
  font-weight: ${({ theme }) => theme.typography.weight.bold};
  font-size: ${({ theme }) => theme.typography.size.l3}px !important;
  line-height: 80px;
  letter-spacing: 0.4px;
  @media screen and (max-width: ${({ theme }) => theme.mediaScreen.mobile}) {
    font-size: 22px !important;
    line-height: 34px;
  }
`;
export const Headline3White = styled.h2`
  color: ${({ theme }) => theme.color.white};
  font-weight: ${({ theme }) => theme.typography.weight.bold};
  font-size: ${({ theme }) => theme.typography.size.l2}px !important;
  line-height: 70px;
  letter-spacing: -1px;
  @media screen and (max-width: ${({ theme }) => theme.mediaScreen.mobile}) {
    font-size: 22px !important;
    line-height: 34px;
  }
`;

export const Headline3 = styled.h3`
  font-family: ${({ theme }) => theme.typography.family.pretendard};
  color: ${({ theme }) => theme.color.black};
  font-style: normal;
  font-weight: 700;
  font-size: 45px;
  line-height: 80px;

  @media screen and (max-width: ${({ theme }) => theme.mediaScreen.mobile}) {
    font-weight: 700;
    font-size: 30px;
    line-height: 50px;
    white-space: pre-wrap;
    word-break: keep-all;
    text-align: center;
    word-break: break-word;
    overflow-wrap: break-word;
  }
`;

export const Headline4Bold = styled.h3`
  font-family: ${({ theme }) => theme.typography.family.pretendard};
  color: ${({ theme }) => theme.color.black};
  font-style: normal;
  font-weight: 700;
  font-size: 42px;
  line-height: 56px;
  letter-spacing: 0.4px;

  @media screen and (max-width: ${({ theme }) => theme.mediaScreen.mobile}) {
    font-size: ${({ theme }) => theme.typography.size.s4}px;
    line-height: 20px;
  }
`;
export const Headline4 = styled.h4`
  font-family: ${({ theme }) => theme.typography.family.pretendard};
  color: ${({ theme }) => theme.color.black};
  font-style: normal;
  font-weight: 600;
  font-size: ${({ theme }) => theme.typography.size.l1}px;
  line-height: 46px;
  letter-spacing: 0.4px;

  @media screen and (max-width: ${({ theme }) => theme.mediaScreen.mobile}) {
    font-size: ${({ theme }) => theme.typography.size.s4}px;
    line-height: 20px;
  }
`;

export const Paragraph1 = styled.p`
  font-family: ${({ theme }) => theme.typography.family.pretendard};
  color: ${({ theme }) => theme.color.g100};
  font-style: normal;
  font-weight: 600;
  font-size: ${({ theme }) => theme.typography.size.l1}px;

  @media screen and (max-width: ${({ theme }) => theme.mediaScreen.mobile}) {
    font-size: ${({ theme }) => theme.typography.size.s4}px;
    line-height: 10px;
  }
`;
export const Paragraph1Gray = styled.p`
  font-family: ${({ theme }) => theme.typography.family.pretendard};
  color: ${({ theme }) => theme.color.g900};
  font-style: normal;
  font-weight: 600;
  font-size: ${({ theme }) => theme.typography.size.l1}px;

  @media screen and (max-width: ${({ theme }) => theme.mediaScreen.mobile}) {
    font-size: ${({ theme }) => theme.typography.size.s4}px;
    line-height: 10px;
  }
`;

export const Paragraph1Bold = styled.p`
  font-family: ${({ theme }) => theme.typography.family.pretendard};
  color: ${({ theme }) => theme.color.black};
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 32px;

  @media screen and (max-width: ${({ theme }) => theme.mediaScreen.mobile}) {
    font-size: 18px;
    line-height: 24px;
  }
`;

export const Paragraph2 = styled.p`
  font-family: ${({ theme }) => theme.typography.family.pretendard};
  color: ${({ theme }) => theme.color.black};
  font-weight: 600;
  font-size: ${({ theme }) => theme.typography.size.m3}px !important;
  line-height: 55px !important;

  @media screen and (max-width: ${({ theme }) => theme.mediaScreen.mobile}) {
    font-size: ${({ theme }) => theme.typography.size.m1}px !important;
    line-height: 24px;
  }
`;

export const Paragraph2Bold = styled.p`
  font-family: ${({ theme }) => theme.typography.family.pretendard};
  color: ${({ theme }) => theme.color.black};
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 28px;

  @media screen and (max-width: ${({ theme }) => theme.mediaScreen.mobile}) {
    font-size: 16px;
    line-height: 24px;
  }
`;
export const Paragraph2White = styled.p`
  font-family: ${({ theme }) => theme.typography.family.pretendard};
  color: ${({ theme }) => theme.color.white};
  font-weight: 600;
  font-size: 22px;
  line-height: 45px;

  @media screen and (max-width: ${({ theme }) => theme.mediaScreen.mobile}) {
    font-size: 14px;
    line-height: 20px;
  }
`;
export const Paragraph3 = styled.p`
  font-family: ${({ theme }) => theme.typography.family.pretendard};
  color: ${({ theme }) => theme.color.black};
  font-size: ${({ theme }) => theme.typography.size.m2}px !important;
  font-weight: 600;
  line-height: 55px;

  @media screen and (max-width: ${({ theme }) => theme.mediaScreen.mobile}) {
    font-size: 20px !important;
    line-height: 30px !important;
    word-break: keep-all !important;
    white-space: pre-line !important;
  }
`;

export const Paragraph3Bold = styled.p`
  font-family: ${({ theme }) => theme.typography.family.pretendard};
  color: ${({ theme }) => theme.color.black};
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;

  @media screen and (max-width: ${({ theme }) => theme.mediaScreen.mobile}) {
    font-size: 14px;
    line-height: 20px;
  }
`;

export const Paragraph4 = styled.p`
  font-family: ${({ theme }) => theme.typography.family.pretendard};
  color: ${({ theme }) => theme.color.black};
  font-style: normal;
  font-weight: 400;
  font-size: ${({ theme }) => theme.typography.size.s4}px !important;
  line-height: 30px;

  @media screen and (max-width: ${({ theme }) => theme.mediaScreen.mobile}) {
    font-size: 12px;
    line-height: 16px;
  }
`;

export const Button1 = styled.button`
  font-family: ${({ theme }) => theme.typography.family.pretendard};
  color: ${({ theme }) => theme.color.black};
  font-style: normal;
  font-weight: 500;
  font-size: ${({ theme }) => theme.typography.size.s4}px !important;
  /* line-height: 22px; */

  @media screen and (max-width: ${({ theme }) => theme.mediaScreen.mobile}) {
    font-size: 16px;
    /* line-height: 20px; */
  }
`;

export const Button2 = styled.button`
  font-family: ${({ theme }) => theme.typography.family.pretendard};
  color: ${({ theme }) => theme.color.black};
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  /* line-height: 18px; */

  @media screen and (max-width: ${({ theme }) => theme.mediaScreen.mobile}) {
    font-size: 14px;
    /* line-height: 18px; */
  }
`;

export const Button3 = styled.button`
  font-family: ${({ theme }) => theme.typography.family.pretendard};
  color: ${({ theme }) => theme.color.black};
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  /* line-height: 16px; */

  @media screen and (max-width: ${({ theme }) => theme.mediaScreen.mobile}) {
    font-size: 12px;
    /* line-height: 16px; */
  }
`;
