import {
  StyledMainWrapper,
  StyledWrapperTop,
  StyledContentWrapper,
  StyledTitle,
  StyledSubTitle,
  StyledBr,
  StyledResponsiveBr,
} from "./styles";
import { useScrollAnimation } from "../../../../hooks/use-scroll-animation";
import Button from "../../../../components/button";
import { useNavigate } from "react-router-dom";

const ServiceSummary = () => {
  const navigate = useNavigate();
  const { ref, isInViewport } = useScrollAnimation();

  return (
    <StyledMainWrapper>
      <StyledWrapperTop ref={ref} $isFadeActive={isInViewport}>
        <StyledContentWrapper>
          <StyledTitle>군 급식 담당자를 위한 통합 발주 관리 시스템</StyledTitle>
          <StyledSubTitle>
            이지마스는 군 급식 발주를 위한 모든 기능을 <StyledResponsiveBr /> 한
            곳에 모은 서비스입니다 <StyledResponsiveBr />
            <StyledBr /> 복잡한 발주 절차와 수기 관리의 불편함을 해소하고,{" "}
            <StyledResponsiveBr />
            <StyledBr />
            정확하고 체계적인 발주 관리를 가능하게 합니다.
          </StyledSubTitle>
          <img
            src="/image/client/summary1.png"
            alt="mainSection2"
            width={624}
            height={664}
          />
        </StyledContentWrapper>
      </StyledWrapperTop>
    </StyledMainWrapper>
  );
};

export default ServiceSummary;
