import styled from "styled-components";

const StyledFooter = styled.footer`
  width: 100%;
  box-sizing: border-box;
  padding: 40px;
  display: flex;
  justify-content: center;
  background-color: ${({ theme }) => theme.color.black};

  @media screen and (max-width: 768px) {
    padding: 40px 20px;
  }
`;
const StyledWrpper = styled.div`
  display: flex;
  width: 100%;
  max-width: 1440px;
  justify-content: space-between;
`;
const StyeldInfoCon = styled.div``;
const StyledWebSiteInfoWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  & > div {
    display: flex;
    flex-wrap: wrap;
  }
  @media screen and (max-width: ${({ theme }) => theme.mediaScreen.mobile}) {
    gap: 0;
  }
`;
const StyledLogoWrapper = styled.div``;
const StyledTitle = styled.p`
  display: inline;
  font-family: ${({ theme }) => theme.typography.family.pretendard};
  font-weight: 700;
  font-size: 16px;
  line-height: 26px;
  color: ${({ theme }) => theme.color.white};
`;
const StyledInfo = styled.p`
  display: inline;
  font-family: ${({ theme }) => theme.typography.family.pretendard};
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;

  color: ${({ theme }) => theme.color.g100};
`;

const StyledInfoContour = styled.span`
  display: inline-block;
  margin: 0px 4px;
  background-color: ${({ theme }) => theme.color.g100};
  width: 1px;
  height: 15px;
  @media screen and (max-width: ${({ theme }) => theme.mediaScreen.mobile}) {
    display: none;
  }
`;

const StyledInfoContour1 = styled.span`
  display: inline-block;
  background-color: ${({ theme }) => theme.color.g100};
  width: 1px;
  height: 13px;

  @media screen and (max-width: ${({ theme }) => theme.mediaScreen.mobile}) {
    height: 10px;
  }
`;

const StlyedPolicyWrapper = styled.div`
  display: flex;
  gap: 24px;
`;

const StyledPolicy = styled.p`
  display: inline;
  font-size: 16px;
  color: ${({ theme }) => theme.color.lightGray};
  font-weight: 600;
  line-height: 26px;
  cursor: pointer;
  transition: 0.3s all ease-in-out;
  white-space: nowrap;
  &:hover {
    color: ${({ theme }) => theme.color.g100};
  }
  // @media screen and (max-width: ${({ theme }) => theme.mediaScreen.tablet}) {
  //   font-size: 16px;
  // }
  // @media screen and (max-width: ${({ theme }) => theme.mediaScreen.mobile}) {
  //   font-size: ${({ theme }) => theme.typography.size.s1}px;
  // }
`;
const StyledCopyrightWrapper = styled.div``;
const StyledCopyright = styled.p`
  font-size: 16px;
  color: ${({ theme }) => theme.color.lightGray};
  line-height: 26px;
  font-weight: ${({ theme }) => theme.typography.weight.regular};
  margin-top: 32px;
`;

const StyledFlexWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  @media screen and (max-width: ${({ theme }) => theme.mediaScreen.tablet}) {
    gap: 15px;
  }
`;
const StyledInfoWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 0px 8px;
  flex-wrap: wrap;
`;
const StyledInfoWrapper2 = styled.div`
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
`;
const StyledInfoWrapper3 = styled.div`
  margin-top: 24px;
  display: flex;
  align-items: center;
  gap: 0px 8px;
  flex-wrap: wrap;
`;

const StlyedPolicyMobileWrapper = styled(StlyedPolicyWrapper)`
  display: flex;
  gap: 0px 24px;
  flex-wrap: wrap;
`;

export {
  StyledFooter,
  StyledWrpper,
  StyledWebSiteInfoWrapper,
  StyledLogoWrapper,
  StyledTitle,
  StyledInfo,
  StyledInfoContour,
  StyledInfoContour1,
  StlyedPolicyWrapper,
  StyledPolicy,
  StyledCopyrightWrapper,
  StyledCopyright,
  StyledFlexWrapper,
  StyledInfoWrapper,
  StyledInfoWrapper2,
  StyledInfoWrapper3,
  StyeldInfoCon,
  StlyedPolicyMobileWrapper,
};
