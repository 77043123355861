import React from "react";
import {
  StyledInnerWrapper,
  StyledWrapper,
  StyledComment,
  StyledAccentComment,
  StyledContentWrapper,
  StyledNumbering,
  StyledFeatureText,
  StyledBr,
  StyledResponsiveBr,
} from "./styles";

const ServiceComment = () => {
  return (
    <StyledWrapper>
      <StyledInnerWrapper>
        <StyledComment>
          🫡 군부대에서 모바일 앱으로
          <StyledResponsiveBr /> 쉽게 접근하는 MAS
        </StyledComment>
        <StyledContentWrapper>
          <StyledNumbering>1</StyledNumbering>
          <StyledFeatureText>
            MAS에 등록된 제품을 쉽게
            <StyledResponsiveBr /> 검색해줘요!
          </StyledFeatureText>
        </StyledContentWrapper>
        <StyledContentWrapper>
          <StyledNumbering>2</StyledNumbering>
          <StyledFeatureText>
            업체 담당자와 다이렉트로 연결!
            <StyledResponsiveBr />
            {/* 
            <StyledBr /> <StyledBr /> */}
            📱전화, 💬카카오채널, ✉️쪽지 가능!
          </StyledFeatureText>
        </StyledContentWrapper>
      </StyledInnerWrapper>
    </StyledWrapper>
  );
};

export default ServiceComment;
