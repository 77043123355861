export type APP_REVIEW = {
  job: string;
  name: string;
  title: string;
  comment: string;
};
export const APP_REVIEW_LIST = [
  {
    job: "10급양대",
    name: "김하선",
    title: "이지마스 덕분에 식자재 발주가 너무 편해졌어요!",
    comment:
      "이지마스 덕분에 식자재 발주가 너무 편해졌어요! 전화하고 서류작업 하던 시절은 이제 안녕~.",
  },
  {
    job: "제77 기동전대",
    name: "고민서",
    title: "검색부터 주문까지 한 번에 되니 시간이 많이 절약돼요!!",
    comment:
      "검색부터 주문까지 한 번에 해결되니 시간이 많이 절약돼요. 그리고 자주 사용하는 물품은 장바구니에 보관할 수 있어서 번잡하고 동일한 작업을 피할 수 있어요.",
  },
  {
    job: "방수사",
    name: "현나은",
    title: "다양한 식자재 정보를 쉽게 알 수 있어요!!",
    comment:
      "급식에 필요한 다양한 업체의 식자재 정보를 한눈에 볼 수 있어서 메뉴 계획 세우기에 많이 도움되요. 계속 새로운 업체와 품목이 늘어나고 있는데 더욱 많아졌으면 좋겠어요.",
  },
  {
    job: "제064 보병사단 (한라산 부대)",
    name: "최지수",
    title: "이지마스 사용하니까 너무 좋아요!!",
    comment:
      "앱 인터페이스가 직관적이라 설치하고 바로 사용했어요. 배송/조회 기능도 유용하고, 발주날짜도 별도로 설정 가능해서 너무너무 잘 쓰고 있어요",
  },
  {
    job: "제2333 보충대",
    name: "길정아",
    title: "GOOD!! GOOD!!",
    comment:
      "식자재 발주의 래퍼다임 전환 !! 항상 생각하던 앱이었는데 드디어 나왔네요.",
  },
  {
    job: "흑룡수호 사단",
    name: "엄지아",
    title: "사용이 편하고 대응도 아주 좋았어요!!",
    comment:
      "항상 전화로만 했는데 앱으로 하니 아주 좋아요. 그리고 잘 모르는 부분이 있어서 문의드렸더니 아주 빠르게 답해주셔서 아주 감사했어요.",
  },
  {
    job: "제5급양대",
    name: "박사랑",
    title: "이지마스, 이제는 나의 필수앱!!",
    comment:
      "기존 방식은 번거롭고 종종 실수도 있고 확인도 번잡했는데, 이지마스를 쓰고나서 실수도 없어지고 계획수립도 더욱 편해졌어요. 이제는 제 필수앱이에요.",
  },
  {
    job: "수송여단",
    name: "모현숙",
    title: "아주편한 주문과 발주",
    comment:
      "장바구니에 저장해놨다가 필요할 때 바로 주문할 수 있어요. 예전에는 어떻게 물품을 발주했었는지 기억이 안 날 정도로 아주 좋아요. ",
  }
];
