import styled from "styled-components";

export const StyledReviewCardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 60px;
  width: calc(100% - 20px);
  height: 350px;
  box-sizing: border-box;
  background-color: ${({ theme }) => theme.color.white};
  border-radius: 20px;
  box-shadow: 0px 10px 32px -4px #18274b1a;
  box-shadow: 0px 6px 14px -6px #18274b1f;
  @media screen and (max-width: ${({ theme }) => theme.mediaScreen.tablet}) {
    padding: 60px 33px;
  }
  @media screen and (max-width: 1024px) {
    width: 100%;
  }
  @media screen and (max-width: ${({ theme }) => theme.mediaScreen.mobile}) {
    padding: 50px 18px;
    width: calc(100% - 20px);
  }
`;
export const StyledReviewCardContentsWrapper = styled.div`
  width: 100%;
  flex-direction: column;
  gap: 40px;
  overflow-y: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    display: none;
  }
`;

export const StyledTitle = styled.h2`
  font-size: 24px;
  line-height: 40px;
  font-weight: 700;
  color: ${({ theme }) => theme.color.black};
`;

export const StyledSpan = styled.span`
  display: block;
  font-size: ${({ theme }) => theme.typography.size.s3}px;
  font-weight: ${({ theme }) => theme.typography.weight.regular};
  color: ${({ theme }) => theme.color.g900};
  line-height: 22px;
`;

export const StyledComment = styled.p`
  font-size: 16px;
  line-height: 22px;
  font-weight: 700;
  color: ${({ theme }) => theme.color.g900};
`;

export const StyledState = styled.span`
  display: block;
  font-size: ${({ theme }) => theme.typography.size.s2}px;
  font-weight: ${({ theme }) => theme.typography.weight.regular};
  color: ${({ theme }) => theme.color.g600};
  line-height: ${({ theme }) => theme.typography.lineheight.s1}px;
`;

export const StyledReviewCardContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  white-space: pre-line;
  word-break: keep-all;
`;
