import React from "react";
import {
  StyledAccentTitle,
  StyledBr,
  StyledComment,
  StyledTitle,
  StyledWrapper,
  StyledResponsiveBr,
  StyledCommentCon,
} from "./styles";

const AppTip = () => {
  return (
    <StyledWrapper>
      <StyledTitle>
        📣 식품업체,{" "}
        <StyledAccentTitle>
          군부대와 직접 소통할 수 있는 새로운 방법
        </StyledAccentTitle>{" "}
        알고 계신가요?
      </StyledTitle>
      <StyledCommentCon>
        <StyledComment>
          군부대와의 접촉 기회가 제한적이고,
          <StyledResponsiveBr /> 전통적으로 대행사를 통한 인쇄 광고에 의존하던{" "}
          <StyledResponsiveBr />
          방식에 한계를 느끼셨나요? <br />
          이지마스는 식품업체가 군부대와 직접 소통할 수<StyledResponsiveBr />{" "}
          있는 혁신적인 기회를 제공합니다. <br />
          앱을 통해 자사 제품 정보를 군 부대 내 영양사에게 전달하고, 군부대와의
          소통을 더 쉽게 만들어 드립니다.
          <StyledBr />
        </StyledComment>
      </StyledCommentCon>
    </StyledWrapper>
  );
};

export default AppTip;
