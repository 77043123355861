import { useNavigate } from "react-router-dom";
import Button from "../../button";
import {
  StyledMenuCon,
  StyledMenuWrapper,
  StyledMobileSubMenuWrapper,
  StyledSpan,
} from "./styles";

interface Props {
  isActiveMobileMenu: boolean;
  setIsActiveMobileMenu: React.Dispatch<React.SetStateAction<boolean>>;
  firstPath: string;
}

const MobileSubMenu: React.FC<Props> = ({
  isActiveMobileMenu,
  firstPath,
  setIsActiveMobileMenu,
}) => {
  const navigate = useNavigate();
  const closeMenu = () => {
    setIsActiveMobileMenu(false);
  };
  return (
    <StyledMobileSubMenuWrapper active={isActiveMobileMenu ? 1 : 0}>
      {firstPath === "/client" && (
        <StyledMenuWrapper>
          <StyledMenuCon>
            <StyledSpan>📦이지마스 입점을 원하시나요?</StyledSpan>
            <Button
              type="button"
              color="representative"
              size="medium"
              text="식품업체 입점하기"
              onClick={() => {
                closeMenu();
                navigate("/");
              }}
            />
          </StyledMenuCon>
          {/* 
          <StyledMenuCon>
            <StyledSpan>📦이지마스 무료로 시작하시겠습니까?</StyledSpan>
            <Button
              type="button"
              color="representative"
              size="medium"
              text="무료로 시작하기"
              onClick={() => {
                closeMenu();
                navigate("/client");
              }}
            />            
          </StyledMenuCon>
          */}
          <StyledMenuCon>
            <StyledSpan>😃이지마스가 처음이신가요?</StyledSpan>
            <Button
              type="button"
              color="black"
              size="medium"
              text="앱소개서 다운로드"
              onClick={() => {
                closeMenu();
                window.open("https://www.easymas.kr/upload/easymas.pdf", "_blank");
              }}
            />
            <Button
              type="button"
              color="black"
              size="medium"
              text="매뉴얼 다운로드"
              onClick={() => {
                closeMenu();
                window.open("https://www.easymas.kr/upload/easymas-buyer.pdf", "_blank");
              }}
            />
          </StyledMenuCon>
        </StyledMenuWrapper>
      )}
      {firstPath === "/" && (
        <StyledMenuWrapper>
          <StyledMenuCon>
            <StyledSpan>😃식품 발주를 원하시나요? 👉🏻</StyledSpan>
            <Button
              type="button"
              color="representative"
              size="medium"
              text="식품 발주하러가기"
              onClick={() => {
                closeMenu();
                navigate("/client");
              }}
            />
          </StyledMenuCon>
          {/* 
          <StyledMenuCon>
            <StyledSpan>😃식품업체 입점을 원하시나요?</StyledSpan>
            <Button
              type="button"
              color="representative"
              size="medium"
              text="식품업체 입점하기"
              onClick={() => {
                closeMenu();
                navigate("/");
              }}
            />
          </StyledMenuCon>
          */}
          <StyledMenuCon>
            <StyledSpan>😃이지마스가 처음이신가요?</StyledSpan>
            <Button
              type="button"
              color="black"
              size="medium"
              text="앱소개서 다운로드"
              onClick={() => {
                closeMenu();
                window.open("https://www.easymas.kr/upload/easymas.pdf", "_blank");
              }}
            />
            <Button
              type="button"
              color="black"
              size="medium"
              text="매뉴얼 다운로드"
              onClick={() => {
                closeMenu();
                window.open("https://www.easymas.kr/upload/easymas-vendor.pdf", "_blank");
              }}
            />
          </StyledMenuCon>
        </StyledMenuWrapper>
      )}
    </StyledMobileSubMenuWrapper>
  );
};

export default MobileSubMenu;
