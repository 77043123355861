import {
  StyledFooter,
  StyledWebSiteInfoWrapper,
  StyledInfo,
  StyledInfoContour,
  StyledTitle,
  StyledLogoWrapper,
  StlyedPolicyWrapper,
  StyledPolicy,
  StyledInfoWrapper,
  StyledInfoWrapper2,
  StyledInfoWrapper3,
  StyledCopyrightWrapper,
  StyledCopyright,
  StyledFlexWrapper,
  StyledWrpper,
  StyeldInfoCon,
  StlyedPolicyMobileWrapper,
} from "./style";

const Footer = () => {
  return (
    <StyledFooter>
      <StyledWrpper>
        <StyledWebSiteInfoWrapper>
          <StyledFlexWrapper>
            <StyledLogoWrapper>
              <picture>
                <source
                  srcSet="/image/footerLogo.png"
                  media="(min-width: 769px)"
                />
                <img
                  srcSet="/image/footerLogo.png"
                  alt="image/footerLogo.png"
                />
              </picture>
            </StyledLogoWrapper>
            {/* 
            <StlyedPolicyMobileWrapper>
              <StyledPolicy>개인정보처리방침</StyledPolicy>
              <StyledPolicy>이용약관</StyledPolicy>
              <StyledPolicy>광고 운영정책</StyledPolicy>
              <StyledPolicy>상품판매 운영정책</StyledPolicy>
            </StlyedPolicyMobileWrapper>
            */}
            <StyeldInfoCon>
              <StyledInfoWrapper3>
                <StyledTitle>제이앤제이 스토리</StyledTitle>
                <StyledInfo>대표 이정웅</StyledInfo>
                <span
                  style={{
                    color: "#999999",
                  }}
                >
                  |
                </span>
                <StyledInfo>사업자등록번호 481-52-91969</StyledInfo>
                <StyledInfoContour />
                <StyledInfo>강원특별자치도 홍천군 홍천읍 301</StyledInfo>
              </StyledInfoWrapper3>
              <StyledInfoWrapper>
                <StyledInfo>
                  고객센터 대응센터 : 서울특별시 서초구 방배로 230-1,
                  9층(방배동, 융성빌딩)
                </StyledInfo>
              </StyledInfoWrapper>
              <StyledInfoWrapper2>
                <StyledInfo>
                  <strong>Tel</strong>: 02-536-3742
                </StyledInfo>
                <StyledInfo>
                  <strong>Fax</strong>: 02-536-3334
                </StyledInfo>
                <StyledInfo>
                  <strong>Email</strong>: admin@easymas.kr
                </StyledInfo>
              </StyledInfoWrapper2>
            </StyeldInfoCon>
          </StyledFlexWrapper>
          <StyledCopyrightWrapper>
            <StyledCopyright>
              ⓒ 2024. J&J STORY. All rights reserved
            </StyledCopyright>
          </StyledCopyrightWrapper>
        </StyledWebSiteInfoWrapper>
        {/* 
        <StlyedPolicyWrapper>
          <StyledPolicy>개인정보처리방침</StyledPolicy>
          <StyledPolicy>이용약관</StyledPolicy>
          <StyledPolicy>광고 운영정책</StyledPolicy>
          <StyledPolicy>상품판매 운영정책</StyledPolicy>
        </StlyedPolicyWrapper> 
        */}
      </StyledWrpper>
    </StyledFooter>
  );
};

export default Footer;
