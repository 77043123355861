import { useEffect, useState } from "react";
import { useScrollAnimation } from "../../../../hooks/use-scroll-animation";
import {
  StyledWrapper,
  StyledContentWrapper,
  StyledTitle,
  StyledSubTitle,
  StyledOrange,
  StyledAppCon,
  StyledImageCon,
  StyledInnerWrapper,
  StyledTitleCon,
  StyledTitleClientCon,
} from "./styles";
import { StyledAppClientCon } from "../../../company/components/app-download/styles";

const appDownloads = [
  {
    url: "https://apps.apple.com/kr/app/id6472038203",
    imgSrc: "/image/app-store-2.svg",
  },
  {
    url: "https://play.google.com/store/apps/details?id=kr.easymas.buyer",
    imgSrc: "/image/play-store-2.svg",
  },
];

const AppDownload = () => {
  const { ref, isInViewport } = useScrollAnimation();
  const [adjustWidth, setAdjustWidth] = useState<number>();
  const [adjustHeight, setAdjustHeight] = useState<number>();
  const [imageSrc, setImageSrc] = useState<string>("/image/company/main4.png");

  const calculateDimensions = () => {
    const currentViewWidth = window.innerWidth;
    const currentViewHeight = window.innerHeight;

    if (currentViewWidth / currentViewHeight > 16 / 9) {
      setAdjustHeight(currentViewHeight);
      setAdjustWidth(currentViewWidth);
    } else {
      setAdjustWidth(currentViewWidth);
      setAdjustHeight((currentViewWidth * 9) / 16);
    }

    // if (currentViewWidth <= 370) {
    //   setImageSrc("/image/client/main5.png");
    // }
    // else if (currentViewWidth <= 970) {
    //   setImageSrc("/image/client/main4.png");
    // }
    // else
    // if (currentViewWidth <= 1420) {
    //   setImageSrc("/image/client/main3.png");
    // } else if (currentViewWidth <= 1030) {
    //   setImageSrc("/image/client/main2.png");
    // } else {
    //   setImageSrc("/image/client/main1.png");
    // }
    setImageSrc("/image/client/main3.png");
  };

  useEffect(() => {
    calculateDimensions();

    const handleResize = () => {
      calculateDimensions();
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <StyledWrapper>
      <StyledInnerWrapper ref={ref} $isFadeActive={isInViewport}>
        <StyledContentWrapper>
          <StyledTitleClientCon>
            <StyledTitle>
              MAS를 손안에 <br />
              <StyledOrange>EASY MAS</StyledOrange>
            </StyledTitle>
            <StyledSubTitle>
              군 급식 발주, 이지마스로 더 간편하게!
            </StyledSubTitle>
          </StyledTitleClientCon>
          <StyledAppClientCon>
            {appDownloads.map(({ url, imgSrc }, index) => (
              <a key={index} href={url} target="_blank">
                <img src={imgSrc} />
              </a>
            ))}
          </StyledAppClientCon>
        </StyledContentWrapper>
        <StyledImageCon style={{ display: "flex", justifyContent: "center" }}>
          <img src={imageSrc} alt="mainSection1" />
        </StyledImageCon>
      </StyledInnerWrapper>
    </StyledWrapper>
  );
};

export default AppDownload;
