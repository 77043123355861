import {
  StyledWrapper,
  StyledContainer,
  StyledTitle,
  StyledInnerWrapper,
  StyledContentTitle,
  StyledCommentCon,
  StyledContentComment,
  StyledFlexWrapper,
  StyledContainerSmall1,
  StyledContainerSmall2,
  StyledImageWrapper,
  StyledMobileImageWrapper,
  StyledBr,
  StyledResponsiveBr,
  StyledTabletBr,
} from "./stlyes";

import { ReactComponent as Feature } from "../../../../assets/icons/feature1.svg";
import { ReactComponent as FeatureMobile } from "../../../../assets/icons/feature1M.svg";

const AppFeature: React.FC = () => {
  return (
    <StyledWrapper>
      <StyledInnerWrapper>
        <StyledTitle>간편한 상품검색 및 발주</StyledTitle>
        <StyledContainer>
          <StyledCommentCon>
            <StyledContentTitle>한 번의 클릭으로 발주!</StyledContentTitle>
            <StyledContentComment>
              원하는 식품을 빠르게 검색하고,
              <StyledResponsiveBr /> 한 번의 클릭으로 발주하세요!
              <StyledResponsiveBr />
              <StyledBr />
              카테고리별로 필요한 식품을 손쉽게 찾을 수 있고,
              <StyledResponsiveBr />
              <StyledBr />
              발주도 간편하게 완료할 수 있습니다.
            </StyledContentComment>
          </StyledCommentCon>
          <StyledImageWrapper>
            <Feature />
          </StyledImageWrapper>
          <StyledMobileImageWrapper>
            <FeatureMobile />
          </StyledMobileImageWrapper>
        </StyledContainer>
        <StyledFlexWrapper>
          <StyledContainerSmall1>
            <StyledCommentCon>
              <StyledContentTitle>
                발주예정 식품관리로
                <StyledBr />
                주문예정 식품을 체계적으로 관리!
              </StyledContentTitle>
              <StyledContentComment>
                향후 발주 예정인 식품을 한눈에 확인하고, <StyledResponsiveBr />
                발주 일정을 <StyledBr />
                미리 계획할 수 있어 <StyledResponsiveBr />더 체계적인 식자재
                관리가 가능합니다.
              </StyledContentComment>
            </StyledCommentCon>
          </StyledContainerSmall1>
          <StyledContainerSmall2>
            <StyledCommentCon>
              <StyledContentTitle style={{ color: "black" }}>
                모든 납품 이력을
                <br />
                파악할 수 있는 캘린더 관리
              </StyledContentTitle>
              <StyledContentComment style={{ color: "#999999" }}>
                납품받은 내역을 캘린더 형식으로 관리하여, <br />
                모든 납품 이력을 일목요연하게 파악할 수 있습니다.
              </StyledContentComment>
            </StyledCommentCon>
          </StyledContainerSmall2>
        </StyledFlexWrapper>
      </StyledInnerWrapper>
    </StyledWrapper>
  );
};

export default AppFeature;
