import { Helmet } from "react-helmet";
import RootLayout from "../../components/root-layout";
import ClientSideComponent from "../../components/client-side-component";
import useScrollObserver from "../../hooks/use-move-scroll";
import ClientBody from "../../page-bodies/client";

const ClientPage = () => {
  const { wrapperRef } = useScrollObserver([]);

  return (
    <>
      <Helmet>
        <title>이지마스 - 군부대 식품발주 보조 통합서비스</title>
        <meta name="description" content="Mas를 손안에 EASY MAS" />
      </Helmet>
      <ClientSideComponent>
        <div ref={wrapperRef}>
          <RootLayout>
            <ClientBody />
          </RootLayout>
        </div>
      </ClientSideComponent>
    </>
  );
};

export default ClientPage;
