import { StyledLogoWrapper, StyledLogo } from "./style";
import LOGO_SIZE from "./size";
import { useNavigate } from "react-router-dom";
import usePathname from "../../hooks/use-pathname";
interface Props {
  size?: "large" | "medium" | "small";
  onClick?: () => void;
}

const Logo: React.FC<Props> = ({ size = "medium" }) => {
  const { firstPath } = usePathname();
  const navigate = useNavigate();
  const handleNavi = () => {
    if (firstPath === "/") {
      navigate("/");
    }
    if (firstPath === "/client") {
      navigate("/client");
    }
  };
  return (
    <StyledLogoWrapper size={size}>
      <div onClick={() => handleNavi()}>
        <StyledLogo
          src={"/image/logo.png"}
          width={LOGO_SIZE[size].width}
          height={LOGO_SIZE[size].height}
        />
      </div>
    </StyledLogoWrapper>
  );
};

export default Logo;
