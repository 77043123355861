import {
  StyledWrapper,
  StyledContentWrapper,
  StyledContainer,
  StyledTitle,
  StyledImageWrapper,
  StyledContentCon,
  StyledComment,
  StyledInnerWrapper,
  StyledBr,
  StyledResponsiveBr,
} from "./styles";
import Button from "../../../../components/button";

const AppFeatureMobile: React.FC = () => {
  return (
    <StyledWrapper>
      <StyledInnerWrapper>
        <StyledContainer>
          <StyledContentCon>
            <Button
              type='button'
              size='small'
              color='representative-reverse'
              text='제품 등록 지원'
              radius='5px'
              cursor={false}
            />

            <StyledContentWrapper>
              <StyledTitle>제품 등록을 손쉽게!</StyledTitle>
              <StyledImageWrapper>
                <picture>
                  <source
                    srcSet='/image/company/feature1.png'
                    media='(min-width: 400px)'
                  />
                  <img src='/image/company/feature1M.png' alt='echobox1' />
                </picture>
              </StyledImageWrapper>
              <StyledComment>
                매번 다른 방식으로 발주를 받느라 어려움을 겪고
                <StyledResponsiveBr /> 계신가요? 까다로운 등록 절차도 이지마스가
                도와
                <StyledResponsiveBr />
                드리며, 모든 제품이 군부대에 잘 보이도록 체계적
                <StyledResponsiveBr />
                으로 관리됩니다.
              </StyledComment>
            </StyledContentWrapper>
          </StyledContentCon>
        </StyledContainer>
        <StyledContainer>
          <StyledContentCon>
            <Button
              type='button'
              size='small'
              color='representative-reverse'
              text='발주 관리 자동화'
              radius='5px'
              cursor={false}
            />
            <StyledContentWrapper>
              <StyledTitle>발주부터 납품까지 한눈에!</StyledTitle>
              <StyledImageWrapper>
                <picture>
                  <source
                    srcSet='/image/company/feature2.png'
                    media='(min-width: 400px)'
                  />
                  <img src='/image/company/feature2M.png' alt='echobox1' />
                </picture>
              </StyledImageWrapper>
              <StyledComment>
                발주 예정일, 수량, 납품 완료 여부까지 이지마스를
                <StyledResponsiveBr /> 통해 모든 과정이 한눈에 들어옵니다.
                <StyledResponsiveBr /> 실수 없는 정확한 납품 으로 신뢰를
                높이세요.
              </StyledComment>
            </StyledContentWrapper>
          </StyledContentCon>
        </StyledContainer>
        <StyledContainer>
          <StyledContentCon>
            <Button
              type='button'
              size='small'
              color='representative-reverse'
              text='고객 피드백 모니터링'
              radius='5px'
              cursor={false}
            />
            <StyledContentWrapper>
              <StyledTitle>고객의 목소리를 듣다</StyledTitle>
              <StyledImageWrapper>
                <picture>
                  <source
                    srcSet='/image/company/feature3.png'
                    media='(min-width: 400px)'
                  />
                  <img src='/image/company/feature3M.png' alt='echobox1' />
                </picture>
              </StyledImageWrapper>
              <StyledComment>
                고객의 반응과 피드백을 실시간으로 확인하여
                <StyledResponsiveBr />
                제품의 개선 방향을 파악하고,
                <StyledResponsiveBr /> 더 나은 서비스와 품질을 제공합니다.
              </StyledComment>
            </StyledContentWrapper>
          </StyledContentCon>
        </StyledContainer>
        <StyledContainer>
          <StyledContentCon>
            <Button
              type='button'
              size='small'
              color='representative-reverse'
              text='제품노출과 구매기회 확대'
              radius='5px'
              cursor={false}
            />

            <StyledContentWrapper>
              <StyledTitle>셀링포인트 강화</StyledTitle>{" "}
              <StyledImageWrapper>
                <picture>
                  <source
                    srcSet='/image/company/feature4.png'
                    media='(min-width: 400px)'
                  />
                  <img src='/image/company/feature4M.png' alt='echobox1' />
                </picture>
              </StyledImageWrapper>
              <StyledComment>
                단순한 발주 시스템을 넘어, 여러분의 제품을 더욱
                <StyledResponsiveBr /> 돋보 이게 합니다. 신상품과 할인상품 안내,
                맞춤형
                <StyledResponsiveBr /> 배너 광고 까지 유용한 상품 정보를
                지속적으로 <StyledResponsiveBr />
                제공합니다.
              </StyledComment>
            </StyledContentWrapper>
          </StyledContentCon>
        </StyledContainer>
      </StyledInnerWrapper>
    </StyledWrapper>
  );
};

export default AppFeatureMobile;
