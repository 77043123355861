import { useNavigate } from "react-router-dom";
import {
  StyledWrapper,
  StyledContentWrapper,
  StyledTitle,
  StyledComment,
  StyledContentCon,
  StyledBr,
  StyledResponsiveBr,
  StyledBackgroundImg,
  StyledTablettBr,
} from "./styles";
import Button from "../../../../components/button";
import { useEffect, useState } from "react";

const Banner: React.FC = () => {
  const navigate = useNavigate();

  const [adjustWidth, setAdjustWidth] = useState<number>();
  const [adjustHeight, setAdjustHeight] = useState<number>();

  const calculateDimensions = () => {
    const currentViewWidth = window.innerWidth;
    const currentViewHeight = window.innerHeight;

    if (currentViewWidth / currentViewHeight > 320 / 97) {
      setAdjustHeight(currentViewHeight);
      setAdjustWidth(currentViewWidth);
    } else {
      setAdjustWidth(currentViewWidth);
      setAdjustHeight((currentViewWidth * 97) / 320);
    }

    if (currentViewWidth < 600) {
      if (currentViewWidth / currentViewHeight > 24 / 19) {
        setAdjustHeight(currentViewHeight);
        setAdjustWidth(currentViewWidth);
      } else {
        setAdjustWidth(currentViewWidth);
        setAdjustHeight((currentViewWidth * 19) / 24);
      }
    }
  };
  useEffect(() => {
    calculateDimensions();

    const handleResize = () => {
      calculateDimensions();
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <StyledWrapper style={{ height: adjustHeight }}>
      <StyledContentWrapper>
        <StyledBackgroundImg
          src={adjustWidth > 768 ? "/image/banner.png" : "/image/bannerM.png"}
          $width={adjustWidth}
          $height={adjustHeight >= 768 ? adjustHeight : adjustHeight}
        />
        <StyledContentCon>
          <StyledTitle>지금 이지마스를 경험해보세요!</StyledTitle>
          <StyledComment>
            군 급식 발주를 더 쉽게, 더 정확하게 관리하고 싶다면,
            {/* <StyledResponsiveBr /> */}
            지금 바로 이지마스에 가입하세요.
            <StyledTablettBr />
            <StyledResponsiveBr />
            <StyledBr />군 급식 발주의 효율성을 높이는 첫걸음이 될 것입니다!
          </StyledComment>
        </StyledContentCon>
        <div>
          <Button            
            type="button"
            size={adjustWidth > 1300 ? "full" : "medium"}
            color="representative"
            text="무료로 시작하기"             
            onClick={() => window.open("https://apps.apple.com/kr/app/id6472038203", "_blank")}            
          />
        </div>
        <div>
          <Button
            type="button"
            size={adjustWidth > 1300 ? "full" : "medium"}
            color="lightBlack"          
            text="매뉴얼 다운로드"
            onClick={() => window.open("https://www.easymas.kr/upload/easymas-buyer.pdf", "_blank")}
          /> 
        </div>
      </StyledContentWrapper>
    </StyledWrapper>
  );
};

export default Banner;
