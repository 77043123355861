import { Headline3White, Paragraph2 } from "../../../../styles/typography";
import { styled } from "styled-components";

const StyledWrapper = styled.section`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: ${({ theme }) => theme.color.black};
  box-sizing: border-box;
  gap: 60px;

  padding: 160px 220px;

  @media screen and (max-width: ${({ theme }) => theme.mediaScreen.tablet}) {
    padding: 80px 160px;
  }
  @media screen and (max-width: 875px) {
    gap: 40px;
    padding: 70px 24px;
  }
  @media screen and (max-width: ${({ theme }) => theme.mediaScreen.mobile}) {
    height: 650px;
    padding: 0 18px;
    gap: 40px;
  }
`;

const StyledTitle = styled(Headline3White)`
  text-align: center;
  white-space: pre-line;
  word-break: keep-all;
  @media screen and (max-width: ${({ theme }) => theme.mediaScreen.mobile}) {
    font-size: 22px;
    line-height: 34px;
    letter-spacing: -0.4px;
  }
`;

const StyledAccentTitle = styled.span`
  background-color: ${({ theme }) => theme.color.representative};
  border-radius: 5px;
  letter-spacing: -0.4px;
  margin: 2px;
  padding: 15px 5px;

  @media screen and (max-width: ${({ theme }) => theme.mediaScreen.tablet}) {
    background-color: ${({ theme }) => theme.color.black};
    padding: 0;
  }
`;

export const StyledCommentCon = styled.div`
  @media screen and (max-width: 875px) {
    padding: 0 20px;
  }
`;

const StyledComment = styled(Paragraph2)`
  color: ${({ theme }) => theme.color.white};
  line-height: 60px !important;
  font-weight: 600;
  text-align: center;
  word-break: keep-all;
  white-space: pre-line;
  letter-spacing: -1px;

  @media screen and (max-width: 875px) {
    line-height: 50px !important;
  }
  @media screen and (max-width: ${({ theme }) => theme.mediaScreen.mobile}) {
    font-size: 18px !important;
    line-height: 34px !important;
  }
`;

const StyledBr = styled.br`
  @media screen and (max-width: ${({ theme }) => theme.mediaScreen.mobile}) {
    display: none;
  }
`;

const StyledResponsiveBr = styled.br`
  display: none;
  @media screen and (max-width: ${({ theme }) => theme.mediaScreen.mobile}) {
    display: block;
  }
`;
export {
  StyledWrapper,
  StyledTitle,
  StyledAccentTitle,
  StyledComment,
  StyledBr,
  StyledResponsiveBr,
};
