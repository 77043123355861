import {
  Button1,
  Headline2,
  Paragraph2,
  Paragraph2White,
} from "../../../../styles/typography";
import { styled, css } from "styled-components";

interface Props {
  $bg?: string;
}

const StyledWrapper = styled.section`
  width: 100%;
  display: flex;
  height: 100%;
  position: relative;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  @media screen and (max-width: ${({ theme }) => theme.mediaScreen.tablet}) {
    min-height: 500px;
  }
  @media screen and (max-width: ${({ theme }) => theme.mediaScreen.mobile}) {
    /* margin-top: 60px; */
  }
`;

const StyledContentWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 20px;
  box-sizing: border-box;

  & > div {
    width: 100%;
    display: flex;
    justify-content: center;
    z-index: 2;
  }
  @media screen and (max-width: ${({ theme }) => theme.mediaScreen.tablet}) {
    gap: 40px;
    padding: 20px 0;
  }
  @media screen and (max-width: 865px) {
    gap: 30px;
  }
  @media screen and (max-width: ${({ theme }) => theme.mediaScreen.mobile}) {
    background-image: url("/image/mainBannerM.png");
    gap: 30px;
    & > div {
      box-sizing: border-box;
      padding: 0 20px;
    }
  }
`;

interface Props {
  $width?: number;
  $height?: number;
}

export const StyledBackgroundImg = styled.img<Props>`
  position: absolute;
  left: 0%;
  top: 0;
  width: 100vw;
  object-fit: cover;
  @media screen and (max-width: ${({ theme }) => theme.mediaScreen.tablet}) {
    min-height: 500px;
  }
`;

const StyledContentCon = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  @media screen and (max-width: ${({ theme }) => theme.mediaScreen.mobile}) {
    gap: 10px;
  }
`;

const StyledTitle = styled(Headline2)`
  text-align: center;
  color: ${({ theme }) => theme.color.white};

  @media screen and (max-width: 1100px) {
    font-size: 35px !important;
    line-height: 40px !important;
  }
  @media screen and (max-width: 865px) {
    font-size: 30px !important;
    line-height: 36px !important;
  }
  @media screen and (max-width: ${({ theme }) => theme.mediaScreen.mobile}) {
    white-space: nowrap;
    font-size: 22px !important;
    line-height: 34px !important;
  }
`;

const StyledComment = styled(Paragraph2White)`
  text-align: center;
  color: ${({ theme }) => theme.color.white};

  @media screen and (max-width: 1100px) {
    font-size: 18px !important;
    line-height: 30px !important;
  }
  @media screen and (max-width: 865px) {
    font-size: 16px !important;
    line-height: 28px !important;
  }

  @media screen and (max-width: ${({ theme }) => theme.mediaScreen.mobile}) {
    white-space: pre-line;
    word-break: keep-all;
    font-size: 14px !important;
    line-height: 22px !important;
    letter-spacing: -0.4px;
  }
`;
const StyledBr = styled.br`
  @media screen and (max-width: ${({ theme }) => theme.mediaScreen.tablet}) {
    display: none;
  }
`;

const StyledResponsiveBr = styled.br`
  display: none;
  @media screen and (max-width: ${({ theme }) => theme.mediaScreen.mobile}) {
    display: block;
  }
`;

const StyledTablettBr = styled.br`
  display: none;

  @media screen and (max-width: ${({ theme }) => theme.mediaScreen.tablet}) {
    display: block;
  }
  @media screen and (max-width: ${({ theme }) => theme.mediaScreen.mobile}) {
    display: none;
  }
`;
export {
  StyledWrapper,
  StyledContentCon,
  StyledContentWrapper,
  StyledTitle,
  StyledComment,
  StyledBr,
  StyledResponsiveBr,
  StyledTablettBr,
};
