import Logo from "../logo";
import {
  StyledHeader,
  StyledLogoAndNavWrapper,
  StyledButtonWrapper,
  StyledSpan,
  StyledLogoWrapper,
  StyledMobileButtonWrapper,
  StyledClientButtonWrapper,
} from "./style";
import Button from "../button";
import { useNavigate } from "react-router-dom";
import usePathname from "../../hooks/use-pathname";
import { useEffect, useState } from "react";
import HamburgerButton from "./hamburger-button";
import MobileSubMenu from "./mobile-sub-menu";

const Header = ({}) => {
  const { firstPath } = usePathname();
  const navigate = useNavigate();
  const [adjustWidth, setAdjustWidth] = useState<number>();
  const [isMobileMenuActive, setIsMobileMenuActive] = useState<boolean>(false);

  const calculateDimensions = () => {
    const currentViewWidth = window.innerWidth;

    setAdjustWidth(currentViewWidth);
  };

  useEffect(() => {
    calculateDimensions();

    const handleResize = () => {
      calculateDimensions();
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleNavi = () => {
    if (firstPath === "/") {
      navigate("/client");
    }
    if (firstPath === "/client") {
      navigate("/");
    }
  };
  return (
    <>
      <StyledHeader>
        <div>
          <StyledLogoAndNavWrapper>
            <StyledLogoWrapper>
              <Logo size="medium" />
              <StyledSpan onClick={handleNavi}>
                {firstPath === "/"
                  ? " 😃식품발주를 원하시나요?"
                  : "📦이지마스 입점을 원하시나요?"}
              </StyledSpan>
            </StyledLogoWrapper>
            {firstPath === "/" && (
              <StyledButtonWrapper>
                <Button
                  type="button"
                  color="representative"
                  size="small"
                  text="식품 발주하기"
                  onClick={() => navigate("/client")}
                />
                <Button
                  type="button"
                  color="lightBlack"
                  size="small"
                  text="앱소개서 다운로드"
                  onClick={() => window.open("https://www.easymas.kr/upload/easymas.pdf", "_blank")}                  
                />                 
              </StyledButtonWrapper>
            )}

            {firstPath === "/client" && (
              <StyledClientButtonWrapper>
                <Button
                  type="button"
                  color="representative"
                  size="small"
                  text="식품업체 입점하기"
                  onClick={() => navigate("/")}
                />
                <Button
                  type="button"
                  color="lightBlack"
                  size="small"
                  text="앱소개서 다운로드"
                  onClick={() => window.open("https://www.easymas.kr/upload/easymas.pdf", "_blank")}                  
                />    
              </StyledClientButtonWrapper>
            )}

            <HamburgerButton
              isActiveMobileMenu={isMobileMenuActive}
              setIsActiveMobileMenu={setIsMobileMenuActive}
            />
          </StyledLogoAndNavWrapper>
        </div>
      </StyledHeader>

      <MobileSubMenu
        isActiveMobileMenu={isMobileMenuActive}
        setIsActiveMobileMenu={setIsMobileMenuActive}
        firstPath={firstPath}
      />
    </>
  );
};

export default Header;
