import {
  Headline1,
  Headline1Orange,
  Paragraph1Gray,
} from "../../../../styles/typography";
import { css, keyframes, styled } from "styled-components";

const frameInAnimation = keyframes`
    0% {
         opacity: 0;
         transform: translateY(20%);
   
    }
    100%{
        opacity: 1;
        transform: translateY(0%);
       
    }
`;
interface Props {
  $isFadeActive?: boolean;
  $active?: 1 | 0;
}

const StyledWrapper = styled.section`
  width: 100%;
  display: flex;
  height: 100%;
  box-sizing: border-box;
  justify-content: center;
  overflow: hidden;
  align-items: flex-start;
  height: calc(100vh - 80px);

  /* @media screen and (max-width: 1400px) {
    padding: 50px 0;
  } */
`;
const StyledInnerWrapper = styled.section<Props>`
  width: 100%;
  display: flex;
  box-sizing: border-box;
  justify-content: center;
  transform: translateY(20%);
  padding-left: 0px;
  ${(props) =>
    props.$isFadeActive &&
    css`
      animation: ${frameInAnimation} 0.5s forwards ease-in-out;
    `}

  flex-direction: column;

  @media screen and (max-width: ${({ theme }) => theme.mediaScreen.mobile}) {
    flex-direction: column;
    align-items: center;
    padding-left: 0px;
  }
`;

const StyledOrange = styled(Headline1Orange)``;

const StyledClientOrange = styled(Headline1Orange)`
  /* @media screen and (max-width: 1520px) {
    font-size: 48px !important;
    line-height: 70px;
  } */

  @media screen and (max-width: 1030px) {
    font-size: 54px !important;
    line-height: 65px !important;
  }
  @media screen and (max-width: ${({ theme }) => theme.mediaScreen.tablet}) {
    white-space: nowrap;
  }
`;

const StyledContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 42px;

  padding: 0 20px;
  padding-top: 80px;

  // @media screen and (max-width: ${({ theme }) => theme.mediaScreen.tablet}) {
  //   justify-content: center;
  // }

  // @media screen and (max-width: ${({ theme }) => theme.mediaScreen.mobile}) {
  //   & > img {
  //     width: 120px;
  //     height: 36px;
  //   }
  //   & > img:first-child {
  //     width: 100px;
  //     height: 34px;
  //   }
  }
`;

export const StyledTitleCon = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
`;

export const StyledTitleClientCon = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  justify-content: flex-start;

  width: 100%;
  align-items: center;
  // @media screen and (max-width: ${({ theme }) => theme.mediaScreen.mobile}) {
  // }
`;

const StyledTitle = styled(Headline1)`
  white-space: pre-line;
  word-break: keep-all;
  letter-spacing: -0.4px;
  text-align: center;
`;

const StyledSubTitle = styled.h2`
  margin: 0;
  padding: 0;

  white-space: pre-line;
  word-break: keep-all;
  letter-spacing: -0.4px;
  color: black;
  font-size: 32px;
  line-height: 38px;

  @media screen and (max-width: 768px) {
    text-align: center;
  }
`;

const StyledAppCon = styled.div`
  display: flex;
  gap: 12px;
  @media screen and (max-width: 750px) {
    justify-content: center;
    width: 100%;
  }
`;

const StyledImageCon = styled.div``;
export {
  StyledWrapper,
  StyledContentWrapper,
  StyledTitle,
  StyledSubTitle,
  StyledOrange,
  StyledAppCon,
  StyledImageCon,
  StyledInnerWrapper,
};
