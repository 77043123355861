import { styled } from "styled-components";

const StyledMain = styled.main`
  min-height: calc(100vh - 80px - 308px);
  background-color: ${({ theme }) => theme.color.white};
  @media screen and (max-width: 768px) {
    max-width: 100vw;
    // margin-top: 60px;
    min-height: calc(100vh - 60px - 223px);
    overflow: hidden;
  }
`;

const HeaderHeightAdjust = styled.div`
  height: 80px;

  @media screen and (max-width: 768px) {
    height: 60px;
  }
`;

export { StyledMain, HeaderHeightAdjust };
