import { useNavigate } from "react-router-dom";
import { ReactComponent as HamburgerSvg } from "../../../assets/icons/hamburger.svg";
import { ReactComponent as CloseSvg } from "../../../assets/icons/close.svg";

import { StyledUserWrapper, StyledMobileMenuController } from "./styles";

interface Props {
  isActiveMobileMenu: boolean;
  setIsActiveMobileMenu: (e: boolean) => void;
}

const HamburgerButton: React.FC<Props> = ({
  isActiveMobileMenu,
  setIsActiveMobileMenu,
}) => {
  const navigate = useNavigate();
  const toggleMobileMenu = () => {
    setIsActiveMobileMenu(!isActiveMobileMenu);
  };

  const onBlurMobileMenu = () => {
    setTimeout(() => {
      setIsActiveMobileMenu(false);
    }, 100);
  };

  return (
    <StyledUserWrapper>
      <StyledMobileMenuController>
        <button
          type="button"
          // onBlur={onBlurMobileMenu}
          onClick={toggleMobileMenu}
        >
          {isActiveMobileMenu ? (
            <CloseSvg onClick={toggleMobileMenu} />
          ) : (
            <HamburgerSvg onClick={toggleMobileMenu} />
          )}
        </button>
      </StyledMobileMenuController>
    </StyledUserWrapper>
  );
};

export default HamburgerButton;
