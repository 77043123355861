import React, { useCallback, useMemo, useRef, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {
  NextButtonWrapper,
  PrevButtonWrapper,
  StlyedPrevArrowWrapper,
  StyeldMainBestReviewWrapper,
  StyledBestReviewInnerWrapper,
  StyledBestReviewTitleWrapper,
  StyledMobileButtonWrapper,
  StyledSlideItem,
  StyledSliderInnerWrapper,
  StyledSliderWrapper,
  StyledTitle,
  StyledTitleText,
} from "./styles";

import theme from "../../../../styles/theme";
import ReviewCard from "../../../../components/review-card";
import { APP_REVIEW } from "./review";
import SlideArrowButton from "../../../../components/carousel-arrow-button";

interface AppReviewProps {
  text: string;
  reviewList: APP_REVIEW[];
  backgroundColor?: string;
}

const AppReview: React.FC<AppReviewProps> = ({
  text,
  backgroundColor,
  reviewList,
}) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const slickRef = useRef<Slider>(null);

  const previous = () => {
    if (slickRef.current) {
      slickRef.current.slickPrev();
    }
  };

  const next = () => {
    if (slickRef.current) {
      slickRef.current.slickNext();
    }
  };

  const getCount = useCallback((count: number) => {
    if (reviewList == null) {
      return 0;
    }
    if (reviewList.length < count) {
      return reviewList.length;
    }
    return count;
  }, []);

  const settings = useMemo(
    () => ({
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: getCount(2),
      slidesToScroll: getCount(1),
      centerMode: true,
      centerPadding: "10px",
      arrows: false,
      beforeChange: (oldIndex: number, newIndex: number) => {
        setCurrentSlide(newIndex);
      },
      responsive: [
        {
          breakpoint: 600,
          settings: {
            dots: false,
            infinite: true,
            speed: 500,
            slidesToShow: getCount(1),
            slidesToScroll: getCount(1),
            arrows: false,
            beforeChange: (oldIndex: number, newIndex: number) => {
              setCurrentSlide(newIndex);
            },
          },
        },
        {
          breakpoint: 1024,
          settings: {
            dots: false,
            infinite: true,
            speed: 500,
            slidesToShow: getCount(1),
            slidesToScroll: getCount(1),
            centerMode: true,
            centerPadding: "0px",
            arrows: false,
            beforeChange: (oldIndex: number, newIndex: number) => {
              setCurrentSlide(newIndex);
            },
          },
        },
        {
          breakpoint: 1280,
          settings: {
            dots: false,
            infinite: true,
            speed: 500,
            slidesToShow: getCount(2),
            slidesToScroll: getCount(1),
            centerMode: true,
            centerPadding: "0px",
            arrows: false,
            beforeChange: (oldIndex: number, newIndex: number) => {
              setCurrentSlide(newIndex);
            },
          },
        },
        {
          breakpoint: 768,
          settings: {
            dots: false,
            infinite: true,
            speed: 500,
            slidesToShow: getCount(1),
            slidesToScroll: getCount(1),
            centerMode: true,
            centerPadding: "10px",
            arrows: false,
            beforeChange: (oldIndex: number, newIndex: number) => {
              setCurrentSlide(newIndex);
            },
          },
        },
      ],
    }),
    [setCurrentSlide, getCount]
  );

  const PrevIdx = useMemo(() => {
    if (currentSlide - 1 < 0) {
      return reviewList ? reviewList.length - 1 : 0;
    }
    return currentSlide - 1;
  }, [currentSlide, reviewList]);

  return (
    <StyeldMainBestReviewWrapper
      $backgroundColor={backgroundColor || theme.color.g000}
    >
      <StyledBestReviewInnerWrapper>
        <StyledBestReviewTitleWrapper>
          <StyledTitle>
            <StyledTitleText>{text}</StyledTitleText>
          </StyledTitle>
          <StyledMobileButtonWrapper>
            <StlyedPrevArrowWrapper>
              <SlideArrowButton
                theme="light"
                direction="prev"
                size="medium"
                onClick={previous}
                svgSize={12}
              />
            </StlyedPrevArrowWrapper>
            <SlideArrowButton
              theme="light"
              direction="next"
              size="medium"
              onClick={next}
              svgSize={12}
            />
          </StyledMobileButtonWrapper>
        </StyledBestReviewTitleWrapper>
        <StyledSliderWrapper>
          <StyledSliderInnerWrapper>
            <NextButtonWrapper>
              <SlideArrowButton
                theme="light"
                direction="next"
                size="medium"
                onClick={next}
                svgSize={12}
              />
            </NextButtonWrapper>
            <PrevButtonWrapper>
              <SlideArrowButton
                theme="light"
                direction="prev"
                size="medium"
                onClick={previous}
                svgSize={12}
              />
            </PrevButtonWrapper>
            <Slider {...settings} ref={slickRef}>
              {reviewList.map((review, index) => (
                <StyledSlideItem
                  data-index={index}
                  key={review.name + review.job}
                  $isActive={index === currentSlide || index === PrevIdx}
                  $isCenter={index === currentSlide}
                >
                  <ReviewCard review={review} />
                </StyledSlideItem>
              ))}
            </Slider>
          </StyledSliderInnerWrapper>
        </StyledSliderWrapper>
      </StyledBestReviewInnerWrapper>
    </StyeldMainBestReviewWrapper>
  );
};

export default AppReview;
