import React, { useRef, useState } from "react";
import {
  StyledButton,
  StyledButtonWrapper,
  StyledMobileButton,
  StyledMobilePrevButton,
  StyledPrevButton,
  StyledSliderInnerWrapper,
  StyledSliderWrapper,
  StyledTitle,
  StyledWrapper,
} from "./styles";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { ReactComponent as NextArrow } from "../../../../assets/icons/next.svg";
import { ReactComponent as PrevArrow } from "../../../../assets/icons/prev.svg";

const History = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const slickRef = useRef<Slider>(null);

  const previous = () => {
    if (slickRef.current) {
      slickRef.current.slickPrev();
    }
  };

  const next = () => {
    if (slickRef.current) {
      slickRef.current.slickNext();
    }
  };
  const settings = {
    infinite: true,
    speed: 1000,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: false,
    responsive: [
      {
        breakpoint: 1800,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
          centerMode: true,
          centerPadding: "0px",
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          autoplay: true,
          speed: 1000,
          autoplaySpeed: 3000,
          cssEase: "linear",
        },
      },
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          autoplay: true,
          speed: 1000,
          autoplaySpeed: 2000,
          cssEase: "linear",
          centerMode: true,
          centerPadding: "0px",
        },
      },
    ],
  };
  return (
    <StyledWrapper>
      <StyledTitle>회사 인증 및 수상 내역</StyledTitle>
      <StyledButtonWrapper>
        <StyledMobileButton onClick={previous}>
          <PrevArrow />
        </StyledMobileButton>
        <StyledMobileButton onClick={next}>
          <NextArrow />
        </StyledMobileButton>
      </StyledButtonWrapper>
      <StyledSliderWrapper>
        <StyledPrevButton onClick={previous}>
          <PrevArrow />
        </StyledPrevButton>
        <StyledSliderInnerWrapper>
          <Slider {...settings} ref={slickRef}>
            <img src="/image/company/historyItem1.png" alt="history item 1" />
            <img src="/image/company/historyItem2.png" alt="history item 2" />
            <img src="/image/company/historyItem3.png" alt="history item 3" />
            <img src="/image/company/historyItem1.png" alt="history item 1" />
            <img src="/image/company/historyItem2.png" alt="history item 2" />
            <img src="/image/company/historyItem3.png" alt="history item 3" />
            <img src="/image/company/historyItem1.png" alt="history item 1" />
            <img src="/image/company/historyItem2.png" alt="history item 2" />
            <img src="/image/company/historyItem3.png" alt="history item 3" />
          </Slider>
        </StyledSliderInnerWrapper>
        <StyledButton onClick={next}>
          <NextArrow />
        </StyledButton>
      </StyledSliderWrapper>
    </StyledWrapper>
  );
};

export default History;
