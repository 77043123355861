import styled from "styled-components";

export const StyledWrapper = styled.div`
  width: 100%;
  background-image: url("/image/company/historyBack.png");
  height: 100%;
  min-height: 813px;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 125px;
  background-color: rgba(0, 0, 0, 0.4);
  background-blend-mode: darken;
  position: relative;

  .slick-slide img {
    width: 492px !important;
    height: auto;
    max-height: 350px;
  }
  @media screen and (max-width: 1500px) {
    gap: 50px;
  }
  @media screen and (max-width: 700px) {
    gap: 0px;
  }
  @media screen and (max-width: 500px) {
    gap: 20px;
    min-height: 500px;
    .slick-slide img {
      width: 300px !important;
      height: auto;
      max-height: 200px;
    }
  }
`;

export const StyledTitle = styled.h2`
  font-size: 44px;
  line-height: 80px;
  letter-spacing: -1px;
  text-align: center;
  color: white;
  @media screen and (max-width: 700px) {
    font-size: 33px;
    line-height: 44px;
  }
  @media screen and (max-width: ${({ theme }) => theme.mediaScreen.mobile}) {
    font-size: 22px;
    line-height: 34px;
  }
`;

export const StyledSliderWrapper = styled.div`
  width: 100%;
  box-sizing: border-box;
  max-width: 2000px;
  position: relative;
  display: flex;
  justify-content: center;
  @media screen and (max-width: 2000px) {
    max-width: 1800px;
  }
  @media screen and (max-width: 1200px) {
    max-width: 600px;
  }
  @media screen and (max-width: 500px) {
    max-width: 300px;
  }
`;

export const StyledSliderInnerWrapper = styled.div`
  width: 100%;
  max-width: 1720px;
  overflow: hidden;
  @media screen and (max-width: 1800px) {
    max-width: 1100px;
  }
  @media screen and (max-width: 1200px) {
    max-width: 500px;
  }
  @media screen and (max-width: 500px) {
    max-width: 300px;
  }
`;
export const StyledButton = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.color.white};
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: absolute;
  top: 50%;
  right: 5%;
  @media screen and (max-width: 1800px) {
    right: 0;
  }
  @media screen and (max-width: 1200px) {
    right: 5%;
  }
  @media screen and (max-width: 700px) {
    display: none;
  }
`;
export const StyledPrevButton = styled(StyledButton)`
  top: 50%;
  left: 0;
`;
export const StyledButtonWrapper = styled.div`
  display: none;

  @media screen and (max-width: 700px) {
    display: block;
    width: 100%;
    margin: 20px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 30px;
  }
`;

export const StyledMobileButton = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.color.white};
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const StyledMobilePrevButton = styled(StyledButton)``;
